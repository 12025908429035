const b = document.body;
const container = document.querySelector('.box--work');
const list = document.querySelector('.projects');
const count = document.querySelector('.count');
const navs = document.querySelectorAll('.nav__el');
const titles = document.querySelectorAll('.projects__title');
let isDown = false;
let startY;
let scrollTop;
let startOffset;

let offsetEl;

const el = document.querySelectorAll('.projects__el');
const text = document.querySelectorAll('.projects__text');

const counter = document.querySelector('.counter');
let sectionNumber = 0;
let links = document.querySelectorAll('.link');
const arrows = document.querySelectorAll('.count__arrow');

const media = "(min-width: 1050px)";

let bool;

document.addEventListener("DOMContentLoaded", function () {
    b.classList.add('loading');
});

document.querySelector('.loader__letter--7').addEventListener('transitionend', (e) => {
    b.classList.add('loaded');

    setTimeout(function () {
        b.setAttribute('data-loading', 'false');
    }, 3500);
});

text.forEach(function (i, j) {
    if (!window.matchMedia(media).matches) {
        i.addEventListener('click', (e) => {
            e.preventDefault();
            let att = e.target.getAttribute('data-section');

            b.setAttribute('data-loaders', 'grey');

            setTimeout(function () {
                b.setAttribute('data-section', att);
            }, 800)
        })
    }
})

navs.forEach(function (i, j) {
    i.addEventListener('click', (e) => {
        e.preventDefault();
        let att = e.target.getAttribute('data-section');

        if (att == "info") {
            b.setAttribute('data-loaders', 'black');
        } else {
            b.setAttribute('data-loaders', 'white');
        }

        setTimeout(function () {
            b.setAttribute('data-section', att);
        }, 800)

        navs.forEach(function (k, l) {
            k.classList.remove('nav__el--actif');

            let linkatt = 'nav__el--' + att;

            if (k.classList.contains(linkatt))
                k.classList.add('nav__el--actif');
        })
    })
});

titles.forEach(function (i, j) {
    i.addEventListener('click', (e) => {
        e.preventDefault();
        let att = e.target.getAttribute('data-section');

        b.setAttribute('data-loaders', 'grey');

        setTimeout(function () {
            b.setAttribute('data-section', att);
        }, 800)
    })
});

arrows.forEach(function (element, i) {
    element.addEventListener('click', (e) => {
        let indexCount = parseInt(counter.innerHTML);
        if (e.target.parentNode.classList.contains('count__arrow--left')) {
            if (indexCount == 1) {
                indexCount = 5;

                counter.innerHTML = indexCount;

                el.forEach(function (j, k) {
                    j.classList.remove('projects__el--actif');
                });

                document.getElementById('el-5').classList.add('projects__el--actif');
            } else {
                indexCount--;

                counter.innerHTML = indexCount;

                el.forEach(function (j, k) {
                    j.classList.remove('projects__el--actif');
                });

                document.getElementById(`el-${indexCount}`).classList.add('projects__el--actif');
            }
        } else {
            if (indexCount == 5) {
                indexCount = 1;

                counter.innerHTML = indexCount;

                el.forEach(function (j, k) {
                    j.classList.remove('projects__el--actif');
                });

                document.getElementById('el-1').classList.add('projects__el--actif');
            } else {
                indexCount++;

                counter.innerHTML = indexCount;

                el.forEach(function (j, k) {
                    j.classList.remove('projects__el--actif');
                });

                document.getElementById(`el-${indexCount}`).classList.add('projects__el--actif');
            }

        }
    });
});

container.addEventListener('mousedown', (e) => {
    e.preventDefault();

    bool = false;

    offsetEl = [(document.querySelector('#el-1').offsetTop - (document.querySelector('#el-1').offsetHeight / 3)), (document.querySelector('#el-2').offsetTop - (document.querySelector('#el-1').offsetHeight / 3)), (document.querySelector('#el-3').offsetTop - (document.querySelector('#el-1').offsetHeight / 3)), (document.querySelector('#el-4').offsetTop - (document.querySelector('#el-1').offsetHeight / 3)), (document.querySelector('#el-5').offsetTop - (document.querySelector('#el-1').offsetHeight / 3))];

    if (window.matchMedia(media).matches) {
        if (!e.target.classList.contains('text__title')) {
            isDown = true;
            startY = e.pageY - b.offsetTop;
            startOffset = offsetEl[sectionNumber];
            b.classList.add('active');
            b.classList.add('active--link');
            b.classList.remove('inactive');
        }
    }
});

container.addEventListener('mouseup', (e) => {
    e.preventDefault();
    if (window.matchMedia(media).matches) {
        if (isDown) {
            isDown = false;

            el.forEach(function (i, j) {
                i.classList.remove('projects__el--actif');
            })

            b.classList.remove('active');

            setTimeout(function () {
                b.classList.remove('active--link');
            }, 500);

            setTimeout(function () {
                b.classList.add('inactive');
            }, 1500);

            scrollTo(list, offsetEl[sectionNumber], 500);
            el[sectionNumber].classList.add('projects__el--actif');
        }
    }
});

container.addEventListener('mouseleave', (e) => {
    e.preventDefault();
    if (window.matchMedia(media).matches) {
        if (isDown) {
            isDown = false;

            el.forEach(function (i, j) {
                i.classList.remove('projects__el--actif');
            })

            setTimeout(function () {
                b.classList.remove('active');
            }, 500);

            setTimeout(function () {
                b.classList.remove('active--link');
            }, 1000);

            setTimeout(function () {
                b.classList.add('inactive');
            }, 1500);

            scrollTo(list, offsetEl[sectionNumber], 500);
            el[sectionNumber].classList.add('projects__el--actif');
        }
    }
});

container.addEventListener('mousemove', (e) => {
    if (!isDown) return;
    e.preventDefault();

    if (window.matchMedia(media).matches) {
        const y = e.pageY - b.offsetTop;
        walk = ((y - startY) * 8) - startOffset;

        if (walk > 0) walk = 0;
        if (walk < (-list.scrollHeight)) walk = -list.scrollHeight;

        let walkCount = -(walk / 6);

        if (walk <= (-offsetEl[4])) walkCount = offsetEl[4] / 6;

        list.scrollTop = (-walk);
        count.style.top = walkCount + 'px';

        counterCheck();
    }
})

function counterCheck() {
    offsetEl.forEach(function (element, index) {
        if (walk <= (-element)) {
            sectionNumber = index;
            counter.innerHTML = sectionNumber + 1;
        }
    });
}

function scrollTo(element, to, duration) {
    var start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;

    var animateScroll = function () {
        currentTime += increment;
        var val = Math.easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if (currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
}

//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
};
